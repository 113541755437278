<template>
  <div class="detail">
    <div class="body">
      <div class="body-head">
        <p>{{ detail.title }}</p>
        <p>
          选票时间：{{ detail.format_start_time }} --
          {{ detail.format_end_time }}
        </p>
        <p>{{detail.brief}}</p>
      </div>
      <div class="line"></div>
      <div class="border">
        <load-list :interFace="interFace">
          <template v-slot:list="props">
            <div class="list" v-for="(item, index) in props.list" :key="index">
              <div class="num">{{ index + 1 }}</div>
              <div class="items">
                <img :src="$IMF_PATH + item.img" alt="" v-if="item.img" />
                <div>
                  <!-- <div class="title">
                {{item.title}} 
                <span>区代表</span>
                </div> -->
                  <div :class="['content', item.img ? 'wid-4' : 'wid-5']">
                    {{ item.brief }}
                  </div>
                </div>
              </div>
              <div class="btnList">
                <div class="btn-1" @click="submit(item.id)">投票</div>
                <div class="btn-2" @click="toDetail(item.id)">详情</div>
              </div>
            </div>
          </template>
        </load-list>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import LoadList from "@/components/SearchLoadList";
import { getVoteDetail, subVote } from "../api";
export default {
  components: {
    LoadList,
  },
  data() {
    return {
      radio: 0,
      options: [],
      detail: {},
      icon: {
        normal: require("@/assets/images/home/noSelect.png"),
        active: require("@/assets/images/home/select.png"),
      },
      interFace: {
        api: getVoteDetail,
        params: {
          id: this.$route.params.id,
        },
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.init(id);
  },
  methods: {
    init(id) {
      let params = {
        id: id,
      };
      getVoteDetail(params).then((res) => {
        console.log("radio", res);
        this.detail = res.data.vote;
      });
    },
    toDetail(id){
      this.$router.push({
        name:'voteInfo',
        params:{
          id:id
        }
      })
    },
    submit(id) {
      let params = {
        token: window.localStorage.getItem("token"),
        id: this.$route.params.id,
        oid: id,
      };
      Toast.loading("投票中");
      subVote(params).then((res) => {
        console.log("投票", res);
        if (res.code == 0) {
          Toast.success("投票成功");
          // setTimeout(() => {
          //   this.$router.replace("/voting");
          // }, 1200);
        }
      });
      console.log("radio", this.radio);
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
  box-sizing: border-box;
  background-image: url("../../../assets/images/organization/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 0.01rem;
  .body {
    width: 100%;
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 0.4rem 0.4rem 0 0;
    .body-head {
      width: 100%;
      padding: 0.3rem 0.3rem;
      > p:first-child {
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
      }
      > p:nth-child(2) {
        font-size: 0.24rem;
        color: #666;
        margin-top: 0.16rem;
      }
      > p:last-child {
        font-size: 0.24rem;
        color: #666;
        margin-top: 0.12rem;
      }
    }
    .line {
      width: 100%;
      height: 0.2rem;
      background-color: #f5f5f5;
    }
    .border {
      width: 100%;
      min-height: 1rem;
      .list {
        display: flex;
        align-items: center;
        padding: 0.6rem 0.3rem;
        border-bottom: 1px solid rgba(199, 199, 199, 0.3);
        position: relative;
        .num {
          font-size: 0.48rem;
          color: #3e94ff;
          font-weight: 600;
          margin-right: 0.2rem;
        }
        .items {
          display: flex;
          img {
            width: 1.2rem;
            height: 1.6rem;
            margin-right: 0.2rem;
          }
          .title {
            font-size: 0.36rem;
            color: #333;
            font-weight: 600;
            span {
              font-size: 0.28rem;
              color: #666;
              margin-left: 0.2rem;
            }
          }
          .content {
            width: 5rem;
            padding-right: 0.2rem;
            font-size: 0.24rem;
            color: #666;
          }
          .wid-4 {
            text-indent: 0.48rem;
            width: 4rem;
          }
          .wid-5 {
            width: 5rem;
            text-indent: 0.48rem;
          }
        }
        .btnList {
          width: 1.44rem !important;
          height: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .btn-1 {
            color: #fff;
            background: #3b93ff;
            border-radius: 0.16rem;
            line-height: 0.56rem;
            font-size: 0.28rem;
            letter-spacing: 0.06rem;
            text-align: center;
          }
          .btn-2 {
            color: #fff;
            background: orange;
            border-radius: 0.16rem;
            line-height: 0.56rem;
            font-size: 0.28rem;
            letter-spacing: 0.06rem;
            text-align: center;
          }
          
        }
      }
    }
  }
}
</style>